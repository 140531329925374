/**
 * 经纪人管理-经纪人信息
 * luxinwen
 * 2023-03-22
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_BROKER_MGM_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.nickname" placeholder="请输入微信昵称" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.mobile" placeholder="请输入手机号" clearable />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content">
        <Alert show-icon v-if="!isInit">为确保数据安全，请输入经纪人信息进行查询</Alert>
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" v-else>
          <template slot-scope="params" slot="status">
            <Tag :color="params.row.memberStatus === '3' ? 'error' : 'success'">{{ statusList[params.row.memberStatus] }}</Tag>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="showRealName(params.row)" v-grant="'OSP_BROKER_MGM_REALNAME'">实名信息</Button>
            <Button @click="showBrokerage(params.row)" v-grant="'OSP_BROKER_COMMISSION'">佣金</Button>
            <Button @click="showClient(params.row)" v-grant="'OSP_BROKER_CUSTOMER'">客户</Button>
            <Button @click="showCard(params.row)" v-grant="'OSP_BROKER_MGM_CARD'">银行卡</Button>
            <Button :type="params.row.memberStatus === '3' ? 'default' : 'error'" @click="changeStatus(params.row)" v-grant="'OSP_BROKER_MGM_CHANGE'">{{ params.row.memberStatus === '3' ? '解冻' : '冻结' }}</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <realname v-model="realnameDisplay" :data="realnameData"></realname>
    <card v-model="cardDisplay" :data="cardData"></card>
  </div>
</template>

<script>
  import { MEMBER_STATUS } from '@/util/enum';
  import realname from './realname.vue';
  import card from './card.vue';

  export default {
    components: {
      realname,
      card
    },
    data() {
      return {
        formData: {
          nickname: '', // 昵称
          mobile: '' // 手机号
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '微信昵称',
            key: 'nickname'
          },
          {
            title: '手机号',
            key: 'mobile'
          },
          {
            title: '状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        realnameDisplay: false, // 是否显示实名信息
        realnameData: {},       // 实名信息数据
        cardDisplay: false,
        cardData: {},
        isInit: false,
        statusList: MEMBER_STATUS
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_BROKER_MGM_REALNAME, this.$grant.OSP_BROKER_COMMISSION, this.$grant.OSP_BROKER_CUSTOMER, this.$grant.OSP_BROKER_MGM_CARD, this.$grant.OSP_BROKER_MGM_CHANGE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.isInit = true;
        let data = Object.assign({}, this.formData);
        this.$axios({
          url: this.$api.member.queryMemberList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data || [];
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        if (!this.formData.nickname && !this.formData.mobile) {
          this.notice('请输入微信昵称或手机号');
          return;
        }
        this.getData();
      },
      /**
       * 实名信息
       */
      showRealName(row) {
        this.realnameData = row;
        this.realnameDisplay = true;
      },
      /**
       * 佣金
       */
      showBrokerage(row) {
        this.$router.push({
          name: 'brokerMgmCommission',
          query: {
            memberId: row.memberId
          }
        });
      },
      /**
       * 客户
       */
      showClient(row) {
        this.$router.push({
          name: 'brokerMgmCustomer',
          query: {
            memberId: row.memberId
          }
        });
      },
      /**
       * 银行卡
       */
      showCard(row) {
        this.cardData = row;
        this.cardDisplay = true;
      },
      /**
       * 禁用、启用
       */
      changeStatus(row) {
        let txt = row.memberStatus !== '3' ? '冻结' : '解冻';
        let status = row.memberStatus !== '3' ? 'F' : 'U';
        this.$confirm(`确定要将 [${row.nickname}] 设置为${txt}吗？`).then(() => {
          this.$axios({
            url: this.$api.member.changeMemberStatus,
            data: {
              memberIdList: [String(row.memberId)],
              reason: '业务需要',
              operationType: status
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: txt + '成功'
            });
            this.getData();
          });
        });
      }
    }
  };
</script>